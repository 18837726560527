div.p-multiselect-panel.p-component.p-connected-overlay-enter-done {
  z-index: 3000 !important;
}

div.p-dropdown-panel.p-component.p-connected-overlay-enter-done {
  z-index: 3000 !important;
}

.datatable-div
  .p-datatable.p-datatable-component
  .p-datatable-scrollable-header {
  text-align: left;
  font-size: 1rem;
}

div.datatable-div .p-column-filter {
  width: 100%;
}

.datatable-div
  .p-datatable.p-datatable-component
  .p-datatable-tbody
  > tr:nth-of-type(2n + 1) {
  background-color: #dedede;
}

.datatable-div .p-datatable.p-datatable-component .p-datatable-thead > tr > th {
  text-align: left;
}

.datatable-div .p-datatable.p-datatable-component .p-datatable-scrollable-body {
  max-height: 280px; /* Define uma altura máxima para o corpo da tabela */
  overflow-y: auto; /* Permite rolagem apenas no corpo da tabela */
}

.datatable-div .p-datatable.p-datatable-component .p-datatable-tbody > tr > td {
  cursor: auto;
}

.datatable-div
  .p-datatable.p-datatable-component
  .p-dropdown-label:not(.p-placeholder) {
  text-transform: uppercase;
}

.datatable-div
  .p-datatable-component
  .p-datatable-tbody
  > tr
  > td
  .p-column-title {
  display: none;
}

@media screen and (max-width: 920px) {
  .datatable-div
    .p-datatable.p-datatable-component
    .p-datatable-thead
    > tr
    > th,
  .datatable-div
    .p-datatable.p-datatable-component
    .p-datatable-tfoot
    > tr
    > td {
    display: none !important;
  }

  .datatable-div
    .p-datatable.p-datatable-component
    .p-datatable-scrollable-body {
    overflow: visible;
  }

  .datatable-div .p-datatable.p-datatable-component .p-datatable-tbody > tr {
    border-bottom: 1px solid var(--layer-2);
  }

  .datatable-div .p-datatable.p-datatable-component table > colgroup > col {
    display: none;
  }

  .datatable-div
    .p-datatable.p-datatable-component
    .p-datatable-tbody
    > tr
    > td {
    text-align: left;
    display: flex;
    padding: 0.2rem 1rem;
    border: 0 none !important;
    width: 100% !important;
    float: left;
    clear: left;
    border: 0 none;
  }

  .datatable-div
    .p-datatable.p-datatable-component
    .p-datatable-tbody
    > tr
    > td
    .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    max-width: 30%;
    display: inline-block;
    margin: -0.4rem 1rem -0.4rem -0.4rem;
    font-weight: bold;
  }
}
